import React from "react";

import Layout from "../../../components/theme/layout";
import Seo from "../../../components/seo";
import styled from "styled-components";

import { StaticImage } from "gatsby-plugin-image";
import '../case-study-page.scss'

import CtaSection from "../../../components/cta-section/cta-section.component";

const TensonCaseStudyPage = () => (
    <Layout>
        <Seo title='Tenson Case Study' />

        <div>
            <HeroWrapper>
                <h2>Tenson achieved a 70% engagement rate with interactive shopping</h2>
                <QiwioHeroVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/dHBl51EII3E4kvCG5TErqQ?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioHeroVideoContainer>
            </HeroWrapper>

            <StatsWrapper>
                <Stat>
                    <h2>70%</h2>
                    <p>of visitors engaged with interactive content</p>
                </Stat>
                <Stat>
                    <h2>+56%</h2>
                    <p>increase in video completion rates</p>
                </Stat>
                <Stat>
                    <h2>50%</h2>
                    <p>of visitors landed on product pages</p>
                </Stat>
            </StatsWrapper>

            <AboutCase>
                <div>
                    <StaticImage
                        className="case-logo"
                        src="../../../images/logos/Tenson Logo White.png"
                        alt="Tenson logo"
                        loading="eager"
                        placeholder="none"
                    />
                </div>
                <div>
                    <p><strong>Tenson is a leading Swedish outdoor brand.</strong></p>
                    <p>In 1951, in the little coastal town of Varberg, Sweden, the aspiring entrepreneur Paul Rydholm starts a small-scale production of sportswear together with his sister and two seamstresses.</p>
                    <p>70 years later, Tenson has grown to a leading brand in the outdoor fashion space, selling thousands of products in 23 markets all over the world.</p>
                </div>
            </AboutCase>

            <WebsiteContainer>
                <StaticImage
                    className="tenson-website"
                    style={{
                        gridArea: '1/1'
                    }}
                    src="../../../images/customers/tenson/tenson-website.png"
                    alt="Tenson website"
                    loading="eager"
                    placeholder="none"
                />
                <UseCaseSliderItemOverlay>
                    <span></span>
                </UseCaseSliderItemOverlay>
            </WebsiteContainer>

            <CaseInfo>
                <QiwioVideoContainer>
                    <SquareQiwioVideo src="https://player.qiwio.io/mEOaeVpULnBwxKF6czp9sg?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></SquareQiwioVideo>
                </QiwioVideoContainer>
                <div>
                    <h3>The challenge</h3>
                    <p>Like many eCommerce brands, Tenson focuses on improving their engagement and conversion metrics from their online store.</p>
                    <p>The team at Tenson are very forward thinking and are always looking out for new ways to increase engagement and conversions on their site.</p>
                    <p>Tenson knew that, in order to improve these metrics, they needed to improve the digital shopping experience for their customers. They realised that their customers would come to their site, and often get stuck. They would see inspirational content with products they may be interested in, but they would fail to navigate around the site to find the products they were looking for.</p>
                    <p>These navigational challenges resulted in higher bounce rates and lower conversions.</p>
                </div>
            </CaseInfo>

            <Quote>
                <StaticImage
                    className="case-quote"
                    src="../../../images/customers/tenson/chris.jpeg"
                    style={{
                        borderRadius: '100px'
                    }}
                    alt="Chris Doyle"
                    loading="eager"
                    placeholder="none"
                />
                <h2>“When we look at the <span>future</span> of ecommerce, we think about <span>shoppable content</span>”</h2>
                <p>Chris Doyle</p>
                <p>Director of Marketing & Sustainability @ Tenson</p>
            </Quote>

            <CaseInfo reverse>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/image/f-r-JgmrCI0?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <CaseInfoText>
                    <h3>The Solution</h3>
                    <p>In just a few weeks, the Qiwio team helped Tenson turn their amazing content into interactive 'shoppable' content and embedded it onto their website.</p>
                    <p>Tenson had recently created new product content from their latest expedition up Mount Everest, which included a whole new product line. Before using Qiwio, Tenson's customers could view the product content but failed to find the products they were looking for to read more and buy them.</p>
                    <p>With the help of the Qiwio team, Tenson added hotspots within their product photos and videos with information about the products and links to product pages, so when a website visitor views the content, they have access to product information right inside the content and with 1 click, access the product page and instantly purchase the products that appeared within the content.</p>
                </CaseInfoText>
            </CaseInfo>

            <Quote>
                <StaticImage
                    className="case-quote"
                    src="../../../images/customers/tenson/chris.jpeg"
                    style={{
                        borderRadius: '100px'
                    }}
                    alt="Chris Doyle"
                    loading="eager"
                    placeholder="none"
                />
                <h2>“Qiwio has really enabled us to <span>think differently</span>.<br />We can now create more frictionless shopping experiences!”</h2>
                <p>Chris Doyle</p>
                <p>Director of Marketing & Sustainability @ Tenson</p>
            </Quote>

            <CaseInfo>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/Ta_0VCacNbJLaTgXet-NSg?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <CaseInfoText>
                    <h3>The Results</h3>
                    <p>Qiwio gave Tenson the power to merge inspirational content and shopping technology together to give their customers a more interactive and engaging shopping experience on their website.</p>
                    <p>Being able to have all the product information right inside the content and allowing the customer to easily navigate from content to product page to checkout increased engagement and conversions for Tenson.</p>
                    <p>Tenson achieved a 70% engagement rate with interactive content with 50% of all website visitors clicking through to product pages from the interactive content created.</p>
                    <p>Tenson also experienced that time on site increased, bounce rates decreased and conversions improved whilst using interactive 'shoppable' content on their site.</p>
                </CaseInfoText>
            </CaseInfo>

            <CtaSection />
        </div>
    </Layout>
)
const HeroWrapper = styled.div`
    padding: 50px 20px 0 20px;

    @media screen and (min-width: 900px) {
        padding: 100px 50px 0 50px;
    }

    h2 {
        margin-bottom: 30px;

        @media screen and (min-width: 900px) {
            font-size: 2.5rem;
            max-width: 60vw;
            text-align: center;
            margin: 0 auto 30px auto;
        }
    }
`
const StatsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px 20px;
    max-width: 2000px;
    margin: 0 auto;

    @media screen and (min-width: 900px) {
        padding: 100px 50px;
    }

    @media screen and (min-width: 1000px) {
        flex-direction: row;
    }
`
const Stat = styled.div`
    width: 100%;

    &:not(:last-child) {
        padding-bottom: 30px;
        border-bottom: 2px solid #2E2E39;

        @media screen and (min-width: 1000px) {
            border-bottom: none;
            border-right: 2px solid #2E2E39;
            padding-bottom: 0;
        }
    }

    &:not(:first-child) {
        padding-top: 30px;

        @media screen and (min-width: 1000px) {
            padding-top: 0;
        }
    }

    h2 {
        margin-bottom: 5px;
        font-size: 3.125rem;
    }
`
const AboutCase = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 50px 20px;
    max-width: 2000px;
    margin: 0 auto;

    @media screen and (min-width: 1000px) {
        flex-direction: row;
        padding: 100px 50px 100px 200px;
    }

    div:first-child {
        display: flex;
        justify-content: center;

        @media screen and (min-width: 1000px) {
            min-width: 10vw;
        }
    }

    p {
        font-size: 1rem;
        margin-bottom: 10px;

        &:first-child {
            margin-top: 30px;
        }

        @media screen and (min-width: 1000px) {
            margin-left: 100px;
        }
    }
`
const WebsiteContainer = styled.div`
    padding: 20px 20px 50px 20px;
    margin: 0 auto;
    display: grid;
    justify-content: center;

    @media screen and (min-width: 1000px) {
        flex-direction: row;
        padding: 0 50px 100px 50px;
    }
`
const UseCaseSliderItemOverlay = styled.div`
    grid-area: 1/1;
    position: relative;
    background: linear-gradient(0deg, rgba(28,28,38,1) 0%, rgba(28,28,38,1) 5%, rgba(28,28,38,0.9) 20%, rgba(28,28,38,0) 100%);
`
const QiwioHeroVideoContainer = styled.div`
    width: 100%;
    border-radius: 4px;

    @media screen and (min-width: 1000px) {
        max-width: 60%;
        margin: 0 auto;
    }
`
const QiwioVideoContainer = styled.div`
    width: 100%;
`
const QiwioVideo = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    aspect-ratio: 16 / 9;
    overflow: hidden;

    @media screen and (max-width: 900px) {
        min-height: 220px;
    }
`
const SquareQiwioVideo = styled.iframe`
    border: none;
    width: 100%;
    max-width: 600px;
    aspect-ratio: 1 / 1;
    border-radius: 10px;

    @media screen and (max-width: 900px) {
        min-height: 220px;
    }
`
const CaseInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    max-width: 2000px;
    margin: 0 auto;

    @media screen and (min-width: 1000px) {
        flex-direction: ${({reverse}) => reverse ? 'row-reverse' : 'row'};
        padding: 100px 50px;
    }

    h3 {
        margin: 30px 0 10px 0;
    }

    div:first-child {
        display: flex;
        justify-content: center;

        @media screen and (min-width: 1000px) {
            margin-right: ${({reverse}) => reverse ? '0' : '50px'};
            margin-left: ${({reverse}) => reverse ? '50px' : '0'};
        }
    }
    p {
        font-size: 1rem;
        margin-bottom: 10px;
    }
`
const CaseInfoText = styled.div`
    @media screen and (min-width: 1000px) {
        max-width: 50%;
    }
`
const Quote = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 10px 100px 10px;

    @media screen and (min-width: 1000px) {
        max-width: 80vw;
        margin: 0 auto;
        padding: 100px 50px
    }
    @media screen and (min-width: 1800px) {
        max-width: 1400px;
        margin: 0 auto;
    }

    h2 {
        text-align: center;
        font-weight: 400;
        font-family: 'Barlow';
        margin-bottom: 10px;
    }

    span {
        color: #ff5679;
    }

    p {
        font-family: 'Barlow Semi Condensed';
        text-align: center;
    }
    p:last-child {
        font-family: 'Menlo';
        font-size: 0.875rem;

    }
`
export default TensonCaseStudyPage